<script setup>
import { CARDS_COLLECTION } from './constants.js';

const { $tracking } = useNuxtApp();

const isVisibleN = ref(false);
const shouldAnimate = ref(false);
const sectionRef = ref(null);

let observer = null;

// ### MOUNTED
onMounted(() => {
  observer = new IntersectionObserver((entries) => {
    const isVisible = entries[0].isIntersecting;
    // Trigger your animation here
    isVisibleN.value = isVisible;
    if (isVisible && !shouldAnimate.value) {
      shouldAnimate.value = true;
    }
  });
  observer?.observe(sectionRef.value);
});

onBeforeUnmount(() => {
  observer?.unobserve(sectionRef?.value);
});

// ### TRACKING
function onHostelCardClick() {
  $tracking.onHostelCardClick();
}
</script>

<template>
  <section
    ref="sectionRef"
    class="hc-section-wrapper"
  >
    <div
      v-if="isVisibleN"
      class="cards-mobile-container"
    >
      <div class="row first-row">
        <HomepageHwBestHostelsCard
          v-for="card in CARDS_COLLECTION.firstRow"
          :key="card.place"
          :place="card.place"
          :city="$t(card.city)"
          :country="card.country"
          :asset-url="card.assetUrl"
          :is-movie="card.isMovie"
          :url="card.url"
          :city-url="card.cityUrl"
          :country-url="card.countryUrl"
          @card-click="() => onHostelCardClick()"
        />
        <HomepageHwBestHostelsCard
          v-for="card in CARDS_COLLECTION.firstRow"
          :key="`${card.place}-clone`"
          :place="card.place"
          :city="$t(card.city)"
          :country="card.country"
          :asset-url="card.assetUrl"
          :is-movie="card.isMovie"
          :url="card.url"
          :city-url="card.cityUrl"
          :country-url="card.countryUrl"
          aria-hidden="true"
          @card-click="() => onHostelCardClick()"
        />
      </div>

      <div class="row second-row">
        <HomepageHwBestHostelsCard
          v-for="card in CARDS_COLLECTION.secondRow"
          :key="card.place"
          :place="card.place"
          :city="$t(card.city)"
          :country="card.country"
          :asset-url="card.assetUrl"
          :is-movie="card.isMovie"
          :url="card.url"
          :city-url="card.cityUrl"
          :country-url="card.countryUrl"
          @card-click="() => onHostelCardClick()"
        />
        <HomepageHwBestHostelsCard
          v-for="card in CARDS_COLLECTION.secondRow"
          :key="`${card.place}-clone`"
          :place="card.place"
          :city="$t(card.city)"
          :country="card.country"
          :asset-url="card.assetUrl"
          :is-movie="card.isMovie"
          :url="card.url"
          :city-url="card.cityUrl"
          :country-url="card.countryUrl"
          aria-hidden="true"
          @card-click="() => onHostelCardClick()"
        />
      </div>
    </div>

    <div
      v-if="isVisibleN"
      class="cards-desktop-container"
      :class="{ animate: shouldAnimate }"
    >
      <HomepageHwBestHostelsCard
        v-for="card in CARDS_COLLECTION.desktop"
        :key="card.place"
        :place="card.place"
        :city="$t(card.city)"
        :country="card.country"
        :asset-url="card.assetUrl"
        :is-movie="card.isMovie"
        :url="card.url"
        :city-url="card.cityUrl"
        :country-url="card.countryUrl"
        @card-click="() => onHostelCardClick()"
      />
    </div>

    <div
      v-if="isVisibleN"
      class="text"
      :class="{ animate: shouldAnimate }"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div
        class="text-header"
        v-html="$t('t_HOSTELSSECTIONHEADER')"
      />
      <p class="text-body">
        {{ $t('t_HOSTELSSECTIONBODY') }}
      </p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.hc-section-wrapper {
  padding: $wds-spacing-xxxl 0;
  overflow: hidden;
  position: relative;

  @include desktop {
    height: wds-rem(1280px);
    padding-bottom: $wds-spacing-xl;
  }

  @media screen and (width >= 2048px) {
    left: calc((100% - 1600px) / 2);
    width: calc(100% - calc((100% - 1600px) / 2));
  }

  .row {
    width: fit-content;
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 1;
    column-gap: calc(#{$wds-spacing-l} + #{$wds-spacing-xs});
    margin-left: calc(#{$wds-spacing-l} + #{$wds-spacing-xs});

    @include tablet {
      margin-left: unset;
    }

    &:nth-child(2) {
      margin-top: $wds-spacing-xxl;
    }

    &.first-row {
      animation: first-row-flow 10s linear infinite;

      @include tablet {
        animation: first-row-flow-large 12s linear infinite;
      }

      @keyframes first-row-flow {
        from {
          transform: translateX(0);
        }

        to {
          transform: translateX(-848px);
        }
      }

      @keyframes first-row-flow-large {
        from {
          transform: translateX(0);
        }

        to {
          transform: translateX(-1045px);
        }
      }
    }

    &.second-row {
      animation: second-row-flow 8s linear infinite;

      @include tablet {
        animation: second-row-flow-large 10s linear infinite;
      }

      @keyframes second-row-flow {
        from {
          transform: translateX(-848px);
        }

        to {
          transform: translateX(0);
        }
      }

      @keyframes second-row-flow-large {
        from {
          transform: translateX(-1045px);
        }

        to {
          transform: translateX(0);
        }
      }
    }
  }

  .text {
    max-width: wds-rem(672px);
    margin-top: $wds-spacing-xxxl;
    padding: 0 $wds-spacing-l;

    @include tablet {
      padding: 0 calc(#{$wds-spacing-xxxl} + #{$wds-spacing-s});
    }

    @include desktop {
      position: absolute;
      left: wds-rem(128px);
      top: wds-rem(418px);

      &.animate {
        animation: hostel-cards-text-appear 1.6s 0.4s ease-out forwards;

        @keyframes hostel-cards-text-appear {
          from {
            transform: translateY(200px);
          }

          to {
            transform: translateY(0);
          }
        }
      }
    }

    @media screen and (width >= 2048px) {
      left: wds-rem(207px);
    }

    &-header {
      @include title-1-bld;

      font-size: wds-rem(40px);
      line-height: wds-rem(48px);
      font-weight: 700;
      margin-bottom: $wds-spacing-m;

      & :deep(span) {
        color: $wds-color-green-light;
      }

      @include tablet {
        font-weight: 700;
      }
    }

    &-body {
      @include title-6-reg;
    }
  }
}

.cards-mobile-container {
  @include desktop {
    display: none;
  }
}

.cards-desktop-container {
  display: none;

  @include desktop {
    display: flex;
  }

  &.animate {
    @keyframes hostel-card-animation-1 {
      from {
        transform: translate(-50px, 300px) rotate(-15deg);
      }

      to {
        transform: translate(0, 0) rotate(-15deg);
      }
    }

    @keyframes hostel-card-animation-2 {
      from {
        transform: translate(-150px, 300px) rotate(-15deg);
      }

      to {
        transform: translate(0, 0) rotate(-15deg);
      }
    }

    @keyframes hostel-card-animation-3 {
      from {
        transform: translate(0, 300px) rotate(-15deg);
      }

      to {
        transform: translate(0, 0) rotate(-15deg);
      }
    }

    @keyframes hostel-card-animation-4 {
      from {
        transform: translate(300px, 500px) rotate(-15deg);
      }

      to {
        transform: translate(0, 0) rotate(-15deg);
      }
    }

    @keyframes hostel-card-animation-5 {
      from {
        transform: translate(150px, 0) rotate(-15deg);
      }

      to {
        transform: translate(0, 0) rotate(-15deg);
      }
    }

    @keyframes hostel-card-animation-6 {
      from {
        transform: translate(80px, 0) rotate(-15deg);
      }

      to {
        transform: translate(0, 0) rotate(-15deg);
      }
    }

    @keyframes hostel-card-animation-7 {
      from {
        transform: translate(200px, 0) rotate(-15deg);
      }

      to {
        transform: translate(0, 0) rotate(-15deg);
      }
    }

    @keyframes hostel-card-animation-8 {
      from {
        transform: translate(100px, 0) rotate(-15deg);
      }

      to {
        transform: translate(0, 0) rotate(-15deg);
      }
    }

    @keyframes hostel-card-animation-9 {
      from {
        transform: translate(-100px, 0) rotate(-15deg);
      }

      to {
        transform: translate(0, 0) rotate(-15deg);
      }
    }

    @keyframes hostel-card-animation-10 {
      from {
        transform: translate(-300px, 0) rotate(-15deg);
      }

      to {
        transform: translate(0, 0) rotate(-15deg);
      }
    }
  }

  /* prettier-ignore */
  & > * {
    position: absolute;

  }
}

.cards-desktop-container.animate > * {
  position: absolute;
  animation: 1.6s 0.4s ease-out forwards;

  &:nth-child(4) {
    left: 960px;
    top: 480px;
    animation-name: hostel-card-animation-4;
    z-index: 110;
    transform: translate(300px, 500px) rotate(-15deg);

    :deep(video) {
      transform: scale(7.5) rotate(15deg) translate(-50px, -50px);
      animation: hostel-card-image-zoom 1.6s 0.4s ease-out forwards;

      @keyframes hostel-card-image-zoom {
        from {
          transform: scale(7.5) rotate(15deg) translate(-50px, -50px);
        }

        to {
          transform: scale(1) rotate(0) translate(0, 0);
        }
      }
    }
  }

  &:nth-child(1) {
    left: 350px;
    top: 140px;
    animation-name: hostel-card-animation-1;
    transform: translate(-50px, 300px) rotate(-15deg);
  }

  &:nth-child(2) {
    left: 760px;
    top: 160px;
    animation-name: hostel-card-animation-2;
    transform: translate(-150px, 300px) rotate(-15deg);
  }

  &:nth-child(3) {
    left: 1200px;
    top: 180px;
    animation-name: hostel-card-animation-3;
    transform: translate(0, 300px) rotate(-15deg);
  }

  &:nth-child(5) {
    left: 1300px;
    top: 540px;
    animation-name: hostel-card-animation-5;
    transform: translate(150px, 0) rotate(-15deg);
  }

  &:nth-child(6) {
    left: 40px;
    top: 830px;
    animation-name: hostel-card-animation-6;
    transform: translate(80px, 0) rotate(-15deg);
  }

  &:nth-child(7) {
    left: 340px;
    top: 910px;
    animation-name: hostel-card-animation-7;
    transform: translate(200px, 0) rotate(-15deg);
  }

  &:nth-child(8) {
    left: 600px;
    top: 830px;
    animation-name: hostel-card-animation-8;
    transform: translate(100px, 0) rotate(-15deg);
  }

  &:nth-child(9) {
    left: 920px;
    top: 890px;
    animation-name: hostel-card-animation-9;
    transform: translate(-100px, 0) rotate(-15deg);
  }

  &:nth-child(10) {
    left: 1240px;
    top: 950px;
    animation-name: hostel-card-animation-10;
    transform: translate(-300px, 0) rotate(-15deg);
  }
}
</style>
