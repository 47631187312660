<script setup>
defineProps({
  assetUrl: {
    type: String,
    required: true,
  },
  place: {
    type: String,
    required: true,
  },
  city: {
    type: String,
    required: true,
  },
  country: {
    type: String,
    required: true,
  },
  isMovie: {
    type: Boolean,
    default: false,
  },
  url: {
    type: String,
    default: '#',
  },
  cityUrl: {
    type: String,
    default: '#',
  },
  countryUrl: {
    type: String,
    default: '#',
  },
});

const emit = defineEmits(['cardClick']);

function onHostelCardClick(path) {
  if (!path || path === '')
    return;
  emit('cardClick');
  navigateTo(path, { external: true });
}
</script>

<template>
  <div
    class="card-wrapper"
    role="link"
  >
    <video
      v-if="isMovie"
      autoplay
      loop
      muted
      playsinline
      preload="auto"
      class="asset"
      loading="lazy"
      :title="place"
      @click="() => onHostelCardClick(url)"
    >
      <source
        :src="assetUrl"
        type="video/mp4"
      />
    </video>
    <img
      v-else
      :src="assetUrl"
      :alt="place"
      :title="place"
      width="276"
      height="245"
      class="asset"
      loading="lazy"
      @click="() => onHostelCardClick(url)"
    />
    <div class="text">
      <span
        class="place title-6-bld"
        @click="() => onHostelCardClick(url)"
      >{{ place }}</span>
      <span class="location body-3-reg">
        <span @click="() => onHostelCardClick(cityUrl)">{{ city }}</span>,
        <span @click="() => onHostelCardClick(countryUrl)">{{ country }}</span>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-wrapper {
  transform: rotate(-15deg);
  border-radius: $wds-border-radius-xl;
  padding: calc(#{$wds-spacing-xs} + #{$wds-spacing-xxs});
  box-shadow: $wds-shadow-light-xl;
  background-color: $wds-color-white;
  cursor: pointer;
  width: wds-rem(184px);
  height: fit-content;
  display: flex;
  flex-direction: column;

  @include tablet {
    width: wds-rem(232px);
  }

  .asset {
    border-radius: $wds-border-radius-xl;
    margin-bottom: calc(#{$wds-spacing-s} + #{$wds-spacing-xs});
    height: wds-rem(152px);
    object-fit: cover;

    @include tablet {
      height: wds-rem(192px);
    }
  }

  .text {
    padding: 0 $wds-spacing-xs;
    white-space: normal;
    display: flex;
    flex-direction: column;
  }

  .place {
    white-space: nowrap;

    @include tablet {
      @include title-5-bld;
    }
  }

  .location {
    color: $wds-color-ink;
    margin-bottom: $wds-spacing-s;
  }
}
</style>
